/* helper
----------------------------------------------------------------------------------------------*/
@import "../milligram/color";

/* general styling
----------------------------------------------------------------------------------------------*/
.text-content {
  h5 {
    color: #0D0D0D;
    margin-bottom: 12px;
    text-transform: uppercase;
  }
  h3 {
    color: $color-primary;
    font-weight: 700;
    line-height: 1.2;
  }
  ul,ol,h3 {
    padding-right: 50px;
  }
}
.banner-page{
  display: flex;
  justify-content: flex-end; 
  align-items: center;
  position: relative;
  figcaption{
    // padding-left: 235px;
    // width: calc( 100% - 890px );
    width: calc( 100% - 59% );
    position: absolute;
    margin: auto; 
    top: 50%;
    transform: translateY(-50%);
    padding-right: calc((100vw - 970px) / 2); 
    left: calc((100vw - 970px) / 2); 
    h1{
      color: #075289;
      font-weight: 700;
      margin: 0;
    }
    h4{
      color: #5AB5E1;
      font-weight: 700;
      margin-bottom: 16px;
    }
  }
  figure{
    border-radius: 0 0 0 20%;
    overflow: hidden;
    width: 52%;
    img {
      width: 100%; 
    }
  }
}
.breadcrumb {
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  ul {
    display: flex;
    margin: 0;
    li {
      position: relative;
      padding-right: 30px;
      background: url(/images/material/arr-breadcrumb.svg) no-repeat right top;
      margin: 0 8px 0 0;
      a {
        display: block;
        color: #B8B8B8;
        position: relative;
        &.active {
          color: #212121;
          opacity: 0.7;
        }
      }
      &:last-child {
        padding-right: 0;
        background: none;
      }
    }
  }
}
.std-content{
  figure.images {
    margin-bottom: 30px; 
    img {
      width: 100%; 
    }
  }
  h3{
    margin-bottom: 16px;
    b{
      color: #075289;
    }
  }
  h4 {
    b{
      color: #075289;
    }
  }
  p, li{
    font-size: 1.125rem;
    line-height: 1.7;
    color: #212121;
    // opacity: 0.8;
  }
  > img{
    border-radius: 20px;
    margin: 30px 0;
    width: 100%;
  }
  hr {
    border-top-color: rgba(187,187,187,.5);
  }
  ul{
    li{
      position: relative;
      padding-left: 20px;
      &::before{
        content: "";
        position: absolute;
        // width: 4px;
        // height: 4px;
        border-radius: 100%;
        left: 0;
        top: 10px;
        background: #212121;
        // opacity: 0.8;
      }
    }
  }
}
.middle{
  padding-bottom: 100px;
  &.no-banner{
    padding-top: 200px;
  }
}
.form{
  background: #1F397D url(/images/material/bg-form.png) no-repeat left top;
  border-radius: 32px;
  padding: 64px 100px;
  color: #fff;
  .title{
    margin-bottom: 40px;
    h4{
      margin-bottom: 16px;
    }
    h5{
      margin-bottom: 0;
      font-weight: 400;
    }
  }
  .row{
    .column{
      margin-bottom: 24px;
    }
    &:last-child{
      .column{
        margin: 0;
      }
    }
  }
}
.bg-circle{
  background: url(/images/material/bg-circle.png) no-repeat top right;
}
hr{
  border: 1px solid #BBBBBB;
}
.filter{
  .row{
    align-items: flex-start;
    display: flex;
  }
  h3{
    margin: 0;
  }
}
.pagination{
  ul{
    display: flex;
    justify-content: center;
    margin: 0;
    li{
      margin: 0 15px;
      a{
        padding: 10px;
        color: #212121;
        opacity: 0.7;
        display: block;
        font-weight: 400;
        transition: all ease-out 0.25s;
      }
      &.active, &:hover{
        a{
          color: #075289;
          font-weight: 700;
          opacity: 1;
          transition: all ease-out 0.25s;
        }
      }
      &.disabled{
        a{
          color: #B8B8B8;
          opacity: 1;
          font-weight: 700;
        }
      }
    }
  }
}
.mobile-only{
  display: none;
}

.grecaptcha-badge {
  z-index: 5; 
  bottom: 40% !important; 
}

.wrap-iframe {
  top: 0; left: 0;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  border-radius: 20px;
  margin-bottom: 30px; 
  iframe {
    width: 100% !important; 
    height: 100% !important; 
    position: absolute; 
    top: 0; 
    left: 0;   
  }
}



/* responsive desktop
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 1600px) {

/* responsive < 1600
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1583px) {
  .banner-page {
    figure {
      width: 61%; 
    }
  }
}

/* responsive < 1400
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1383px) {
  .banner-page {
    figure {
      width: 60%; 
    }
    figcaption {
      padding-right: calc(50vw - 470px);
    }
  } 
}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
  .banner-page{
    figure{
      width: 639px;
      min-height: auto; 
    }
    figcaption{
      // padding-left: 65px;
      // width: calc(100% - 670px);
      margin: 0 65px;
      left: 0; 
      transform: none; 
      bottom: 150px; 
      padding-right: calc((100vw - 970px) / 1);
      h1{
        font-size: 2.375rem;
      }
    }
  }
  .breadcrumb{
    margin-bottom: 30px;
  }
  .bg-circle{
    background: none;
  }
  .middle{
    padding-bottom: 100px;
    &.no-banner{
      padding-top: 145px;
      overflow: hidden; 
    }
  }  
  .form{
    padding: 64px;
  }  
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .middle{
    padding-top: 110px;
    padding-bottom: 60px;
  }
  
  .banner-page{
    flex-flow: wrap;
    figure{
      width: 100%;
      order: 1;
      img{
        width: 100%;
      }
    }
    figcaption{
      padding: 0 45px;
      width: 100%;
      order: 2;
      margin: 0; 
      margin-top: 48px;
      position: relative;
      bottom:0; 
      h4{
        font-size: 1rem;
      }
    }
  }
  .breadcrumb{
    padding-top: 64px;
  }
  .filter{
    .row{
      justify-content: space-between;
      .column{
        &.column-70{
          flex: 0 0 60%;
          max-width: 60%;
        }
      }
    }
  }
  .form{
    padding: 50px 40px;
  }
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .middle{
    padding-top: 71px;
  }
  .text-content, .content-pages {
    p{
      font-size: 0.938rem;
      margin-bottom: 15px;
    }
    ul, ol{
      padding: 0;
      li{
        font-size: 0.938rem;
        &:before{
          width: 5px;
          height: 5px;
          top: 8px;
        }
      }
    }
  }
  .banner-page{
    margin-bottom: 35px;
    figcaption{
      margin-top: 30px;
      padding: 0 30px;
      h4{
        font-size: 0.938rem;
      }
      h1{
        font-size: 1.5rem;
      }
    }
  }
  .breadcrumb{
    display: none;
  }
  // .main-container{
  //   .wrapper{
  //     padding-top: 40px;
  //   }
  // }
  .std-content{
    h3, h4{
      font-size: 1.063rem;
    }
    p, li{
      font-size: 0.938rem;
      margin-bottom: 16px;
    }
    > img{
      margin: 16px 0;
    }
  }
  a{
    &.more{
      font-size: 0.875rem;
    }
  }
  .filter{
    h3{
      margin-bottom: 36px;
    }
    .row{
      flex-flow: wrap;
      .column{
        &.column-70, &.column-30{
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }
  .pagination{
    ul{
      li{
        margin: 0 5px;
      }
    }
  }
  .middle{
    &.no-banner{
      padding-top: 164px;
    }
  }
  .form{
    margin: 0 -30px;
    border-radius: 0;
    padding: 48px 30px;
    .title{
      h4{
        font-size: 1.063rem;
      }
      h5{
        font-size: 0.938rem;
      }
    }
    .row .column.column-50{
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .filter{
    .row .column.column-50{
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 24px;
      &:last-child{
        margin: 0;
      }
    }
  }
}
/* helper
----------------------------------------------------------------------------------------------*/
@import "../milligram/color";

/* header
----------------------------------------------------------------------------------------------*/
header{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;  
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    background: $color-initial;
    height: 0px;
    z-index: 1;
    transition: all ease-out 0.3s;
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
    .logo {
      width: 265px;
      display: block;
      padding: 32px 0;
      transition: all ease-out 0.3s;
      img {
        display: block;
        width: 100%;
      }
    }
  }
  .main-header {
    display: flex;
    align-items: center;
    nav {
      > ul > li {
        > a {
          &:hover,
          &.active {
            &:before {
              width: 100%;
              right: auto;
              transition: all ease-out 0.25s;
            }
          }
        }
        &.have-sub {
          .arrow {
            display: none; 
          }
          > a {
            padding-right: 22px;
            &:after {
              content: '';
              height: 16px;
              width: 16px;
              position: absolute;
              top: 0;
              bottom: 0;
              background: url('/images/material/arrow-menu-2.svg') no-repeat;
              margin: auto;
              left: auto;
              right: 0;
            }
          }
          &:hover {
            > a {
              &:before {
                width: 100%;
                right: auto;
                transition: all ease-out 0.25s;
              }
            }
            .sub-nav {
              display: block;
              > a {}
              ul {
                li {
                  a {
                    &:after {
                      content: '';
                      width: 0px;
                      height: 85%;
                      top: 0;
                      bottom: 0;
                      background: #177EC3;
                      position: absolute;
                      right: -1px;
                      left: auto;
                      transition: all ease-out 0.25s;
                      display: block;
                      margin: auto;

                    }
                    &:hover {
                      &:after {
                        width: 3px;
                        transition: all ease-out 0.25s;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.product-menu {
          .sub-nav {
            width: 960px;
            left: 40%;
            transform: translateX(-43%);
            > ul {
              width: 260px;
              li {
                a {
                  padding-right: 60px;
                  &.active {
                    color: #075289;
                  }
                }
                ul {
                  width: 300px;
                  padding-left: 40px;
                  border-left: 2px solid rgba(12,12,12,.1);
                }
              }
            }
            // &.sub-2 {
            //   width: 670px;
            // }
            // &.sub-3 {
            //   width: 960px;
            //   left: 40%;
            //   transform: translateX(-43%);
            // }
          }
          .main-sub {
            ::-webkit-scrollbar {
              width: 3px; position: absolute; margin-right: 0px;  
              z-index: 11; 
            }		
            ::-webkit-scrollbar-track {
              background: #f1f1f1; border-radius:	3px;	
            }
            ::-webkit-scrollbar-thumb {
              background: #177EC3;
              border-radius:	3px;	
              width: 2px;
            }
            ::-webkit-scrollbar-thumb:hover {
              background: #177EC3; 
            }
            
            ul {
              overflow-x: hidden;
              overflow-y: auto;
              max-height: 225px;
              min-height: 225px;
              width: 100%;
              li {
                a {
                  &:after {
                    content: '';
                    width: 0px;
                    height: 85%;
                    top: 0;
                    bottom: 0;
                    background: #177EC3;
                    position: absolute;
                    right: -1px;
                    left: auto;
                    transition: all ease-out 0.25s;
                    display: block;
                    margin: auto;
                    margin-right: 10px;
                  }
                  &:hover {
                    &:after {
                      width: 3px;
                      transition: all ease-out 0.25s;
                    }
                  }
                }
              }
              
            }
            > ul {
              width: 33.33%;
            }
            .sub-child {
              .arrow2 {
                display: none; 
              }
              > a {
                padding-right: 30px;
                .arr-menu {
                  &:before,&:after {
                    content: '';
                    height: 2px;
                    width: 10px;
                    background: #177EC3;
                    display: block;
                    border-radius: 2px;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    right: 20px;
                    left: auto;
                    transition: all ease-out 0.25s;
                  }
                  &:after {
                    transform: rotate(90deg);
                  }
                }
              }
              &:hover {
                > a {
                  color: #075289;
                  .arr-menu {
                    &:after {
                      transition: all ease-out 0.25s;
                      opacity: 0;
                    }
                  }
                }
                > .sub-menu {
                  display: block;
                }
              }
            }
          }
        }
      }
      ul {
        display: flex;
        margin: 0;
        align-items: center;
        li {
          margin: 0;
          padding: 0 10px;
          position: relative;
          a {
            color: $color-initial;
            font-size: .938rem;
            font-weight: 500;
            display: block;
            padding: 25px 0 25px 0;
            position: relative;
            &:before {
              content: '';
              height: 4px;
              background: $color-primary-light;
              position: absolute;
              left: auto;
              right: 0;
              width: 0;
              bottom: 0;
              transition: all ease-out 0.25s;
              display: block;
              margin: auto;
            }
            &.round {
              padding: 7px 24px;
              border: 2px solid $color-initial;
              border-radius: 20px;
              font-weight: 700;
              transition: all .2s ease-out;
              &:before {
                display: none;
              }
              &:hover,
              &.active {
                background: #5AB5E1;
                border-color: #5AB5E1; 
                color:#fff; 
              }
            }
          }
          .sub-nav {
            position: absolute;
            padding: 35px 50px 40px;
            left: 50%;
            transform: translateX(-50%);
            top: 100%;
            display: none;
            &:before {
              content: '';
              background: $color-initial;
              position: absolute;
              left: 0;
              right: 0;
              border-radius: 20px;
              box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.05);
              top: 0;
              height: 100%;
              z-index: 1;
            }
            h6 {
              font-size: 1.188rem;
              margin-bottom: 20px;
              color: $color-primary;
              font-weight: 700;
              position: relative;
              z-index: 2;
            }
            .main-sub {
              position: relative;
              z-index: 3;
            }
            ul {
              display: block;
              min-width: 210px;
              z-index: 3;
              min-height: 0;
              max-height: 225px;
              li {
                padding: 0;
                position: static;
                a {
                  padding: 5px 0;
                  display: block;
                  color: #333;
                  &:before {
                    display: none;
                  }
                  &:hover,
                  &.act,
                  &.active {
                    color: $color-primary;
                  }
                }
                ul {
                  left: 0;
                  top: 0;
                  bottom: 0;
                }
                .sub-menu {
                  left: calc(33.33% - 2px);
                  position: absolute;
                  top: 0;
                  padding-left: 30px;
                  width: 33.33%;
                  border-left: 2px solid rgba(12,12,12,.1);
                  transition: all ease-out 0.25s;
                  display: none;
                  .sub-menu {
                    width: 100%;
                    left: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
    .lang {
      // color: #8A8A8A;
      color: #fff;
      margin-left: 32px;
      .txt-title {
        display: none; 
      }
      a{
        font-size: .938rem;
        color: #fff;
        &:hover {
          color: $color-initial;
        }
        &.active {
          color: $color-initial;
          font-weight: bold;
        }
      }
    }
    .search-header {
      position: relative;
      margin-left: 24px;
      position: relative;
      .ico-src {
        width: 24px;
        height: 24px;
        display: block;
        background: url('/images/material/ico-search.svg') no-repeat center;
        cursor: pointer;
      }
      .block-search {
        position: absolute;
        width: 0; 
        height: 40px; 
        top: -8px; 
        right: 0; 
        display: block;
        transition: ease-out all .3s;
        overflow: hidden; 
        form {
          position: relative;
          &::placeholder {
            color: #212121; 
          }
          input {
            border: 1px solid #075289;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
            border-radius: 20px;
            height: 40px; 
            line-height: 40px; 
            padding: 0 43px 0 26px; 
            font-size: 14px; 
            color: #212121;
          }
          button {
            position: absolute;
            width: 28px; 
            height: 28px; 
            top: 6px; 
            right: 14px;
            background: url('/images/material/ico-search-2.svg') no-repeat center center;  
            background-size: 17px 17px; 
            padding: 0; 
            border:0; 
          }
        }
      }
      &.act {
        .block-search {
          width: 308px; 
        }
      }
    }
  }
  &.float {
    box-shadow: 0 0 10px rgba(0,0,0,.04);
    height: 76px; 
    &:before {
      height: 100%;
      transition: all ease-out 0.3s;
    }
    .wrapper {
      .logo {
        width: 190px;
        padding: 15px 0;
        transition: all ease-out 0.3s;
      }
    }
    .main-header {
      nav {
        ul {
          li {
            a {
              color: $color-primary;
              &.round {
                border-color: $color-primary;
                &:hover,
                &.active {
                  background: #5AB5E1;
                  border-color: #5AB5E1; 
                  color: #fff; 
                }
              }
            }
            &.have-sub {
              > a {
                &:after {
                  background: url('/images/material/arrow-menu.svg') no-repeat;
                }
              }
            }
          }
        }
      }
      .lang {
        // color: rgba(35, 63, 136, 0.5);
        color: $color-primary;
        a {
          // color: rgba(35, 63, 136, 0.5);
          color: $color-primary;
          &.active {
            color: $color-primary;
          }
          &:hover {
            color: $color-primary;
          }
        }
      }
      .search-header {
        .ico-src {
          background: url('/images/material/ico-search-2.svg') no-repeat center;
        }
      }
    }
  }
  .right-mob{
    display: flex;
    align-items: center;
    display: none;
    .round{
      font-size: 1rem;
      color: #fff;
      font-weight: 700;
      margin-right: 90px;
      padding-right: 30px;
      border-right: 1px solid #fff;
      padding: 10px ;
      border: 2px solid #fff;
      border-radius: 25px;
      position: relative;
      &:after{
        content: "";
        position: absolute;
        height: 100%;
        width: 1px;
        background: #fff;
        right: -30px;
        top: 0;
      }
    }
  }
  .burger-ico {
    width: 32px; height: 20px; position: absolute; right: 0; top: 0; bottom: 0; margin: auto; display: none;
    span {
      height: 2.5px; width: 100%; top: 0; bottom: auto; position: absolute; left: auto; right: 0;
      background: $color-initial; border-radius: 2px; transition: all ease-out 0.3s;
      &:nth-child(1){top: 0;}
      &:nth-child(2){top: 6px;}
      &:nth-child(3){top: 12px;}
    }
    &.act {
      span {
        transition: all ease-out 0.3s;
        &:nth-child(1){top: 5px; transform: rotate(45deg)}
        &:nth-child(2){width: 0;}
        &:nth-child(3){top: 5px;transform: rotate(-45deg)}
      }
    }
  }
}

.nobanner {
  header {
    box-shadow: 0 0 10px rgba(0,0,0,.04);
    height: 76px; 
    &:before {
      height: 100%;
      transition: all ease-out 0.3s;
    }
    .wrapper {
      .logo {
        width: 190px;
        padding: 15px 0;
        transition: all ease-out 0.3s;
      }
    }
    .main-header {
      nav {
        ul {
          li {
            a {
              color: $color-primary;
              &.round {
                border-color: $color-primary;
                &.hover,
                &.active {
                  border-color: #5AB5E1;
                  color: #fff; 
                }
              }
            }
            &.have-sub {
              > a {
                &:after {
                  background: url('/images/material/arrow-menu.svg') no-repeat;
                }
              }
            }
          }
        }
      }
      .lang {
        color: rgba(35, 63, 136, 0.5);
        a {
          color: rgba(35, 63, 136, 0.5);
          &.active {
            color: $color-primary;
          }
          &:hover {
            color: $color-primary;
          }
        }
      }
      .search-header {
        .ico-src {
          background: url('/images/material/ico-search-2.svg') no-repeat center;
        }
      }
    }
  }
}
.under {
  header {
    display: none; 
  }
}




/* footer
----------------------------------------------------------------------------------------------*/
.chat {
  position: fixed;
  width: 60px;
  height: 60px;
  right: 44px;
  bottom: 80px;
  z-index: 8; 
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    padding: 14px;
    border-radius: 30px;
    background-color: #25D366;
    box-shadow: 0 0 10px -1px rgb(15 15 15 / 41%);
    -webkit-box-shadow: 0 0 10px -1px rgb(15 15 15 / 41%);
    -moz-box-shadow: 0 0 10px -1px rgba(15, 15, 15, 0.411);
    img {
      transition: all .2s ease-out;      
    }
    &:hover {
      img {
        transform: scale(1.15); 
      }
    }
  }
}
footer{
  background: #1F3878;
  position: relative;
  padding: 50px 0 10px;
  a {
    transition: all ease-out 0.25s;
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
  }
  .main-footer {
    position: relative;
    padding-bottom: 50px;
    .col {
      padding: 0 20px;
      color: $color-initial;
      
      h6 {
        font-size: .938rem;
        font-weight: 600;
        margin-bottom: 15px;
      }
      p,a {
        color: #BBC7E5;
        font-size: 0.938rem;
        line-height: 1.47;
      }
      a {
        &:hover {
          color: $color-initial;
          transition: all ease-out 0.25s;
        }
      }
      p {
        margin: 0 0 15px;
      }
      .link {
        margin-bottom: 5px;
      }
      &:first-child {
        padding-left: 0;
        padding-right: 100px;
      }
      &:last-child {
        padding-right: 0px;
      }
      .logo-footer {
        width: 215px;
      }
      .nav-footer {
        min-width: 130px;
      }
      .sosmed{
        min-width: 170px;
        max-width: 170px;
        display: flex;
        flex-wrap: wrap;
        li {
          padding-right: 14px;
          flex:0 0 34px; 
          max-width: 34px;
          margin-bottom: 10px; 
          &:last-child {
            margin-right: 0;
          }
          a {
            max-width: 22px; 
            display: block; 
            text-align: center; 
          }
        }
      }
    }
  }
  .copyright {
    border-top: 1px solid rgba(255,255,255,.1);
    padding: 30px 0;
    color: #BBC7E5;
    .wrapper {
      align-items: center;
    }
    p,a {
      color: #BBC7E5;
      font-size: .875rem;
      line-height: 1.2;
    }
    a {
      &:hover {
        color: $color-initial;
        transition: all ease-out 0.25s;
      }
    }
    p {
      margin: 0;
    }
    .menu-bottom {
      a {
        margin-left: 24px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}






/* responsive desktop
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1440px) {
  header {
    .main-header {
      nav {
        ul {
          li {
            a {
              font-size: .875rem;
            }
          }
        }
      }
      .lang {
        font-size: .875rem;
        margin-left: 16px;
        a {
          font-size: .875rem;
        }
      }
      .search-header {
        margin-left: 20px;
      }
    }
  }
}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
  header{
    .main-header{
      display: none; 
      position: fixed; 
      top: 110px; 
      left: 0; 
      right: 0; 
      background:#fff; 
      bottom: 0; 
      padding: 90px 0 125px 0;
      min-height: calc(100% - 110px); 
      height: calc(100% - 110px); 
      overflow: hidden; 
      // min-height: calc((var(--vh, 1vh) * 100) - 110px);
			&::after {
				position: fixed;
				content: ""; 
				width: 100%;
				height: 125px;
				right: 0;
				bottom:0; 
				z-index: 3; 
			}
        nav {
          height: 100%;
          overflow-y: auto;
					position: relative;
					z-index: 2; 
          ul {
            display: block;
            li {
              padding: 0; 
              a {
                color: $color-primary;
                font-size: 1.5rem;
                padding-top: 12px;
                padding-bottom: 12px;
                margin-left: 75px; 
                margin-right: 75px; 
                &:before {
                  display: none; 
                }
                &.round {
                  // border-color: $color-primary;
                  display: none;
                }
              }
              .sub-nav{
                background: #FBFBFB; 
                position: relative;  
                left: 0; 
                transform: none; 
                padding: 10px 75px; 
                &:before {
                  background: #FBFBFB; 
                  border-radius: 0; 
                  box-shadow: none; 
                }
                h6 {
                  display: none; 
                }
                ul{
                  max-height: none;
                  li{
                    position: relative; 
                    a {
                      margin-left: 0;
                      margin-right: 0; 
                      padding: 7px 0;
                      font-weight: 400; 
                      font-size: 1.338rem;
                    }
                    ul{
                      position: relative;
                      left: 0px;
                      width: 100%;
                    }
                    .sub-menu {
                      position: relative;
                      left: 0;
                      width: 100%;
                      transition: none;
                      border-left: 0;
                      .sub-menu {
                        left: 0;
                      }
                    }
                  }
                }
              }
              &.have-sub {
                .arrow {
                  display: block; 
                  content: "";
                  height: 34px;
                  width: 34px;
                  position: absolute;
                  top: 21px;
                  right: 70px;
                  background: url('/images/material/arrow-menu.svg') no-repeat center;
                  background-size: 24px 24px;
                  transition: all .2s ease-out; 
                  z-index: 3; 
                  &.current {                    
                    transform: rotate(-180deg); 
                  } 
                }
                > a {
                  margin-right: 145px; 
                  &:after {
                    background: url('/images/material/arrow-menu.svg') no-repeat;
                    background-size: 24px 24px !important;
                    width: 24px; 
                    height: 24px;  
                    transition: all .2s ease-out; 
                    display: none; 
                  } 
                  &.current {
                    &:after {
                      transform: rotate(-180deg); 
                    }
                  }                 
                }
                .sub-child {
                  position: relative;
                  .arrow2 {
                    display: block !important; 
                    position: absolute;
                    top: 8px; 
                    right: 20px;
                    z-index: 3; 
                    width: 34px; 
                    height: 34px; 
                    &:before,
                    &:after {
                      content: "";
                      width: 16px; 
                      right: 30px; 
                      height: 2px; 
                      background: #212121; 
                      display: block;
                      border-radius: 2px;
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      margin: auto;
                      right: 10px;
                      left: auto;
                      transition: all ease-out 0.25s
                    }
                    &:after {
                      transform: rotate(90deg);
                    }
                    &.act {
                      &:after {
                        width: 0 !important; 
                      }
                    }
                  }
                  > a {      
                    margin-right: 0px;              
                    .arr-menu{
                      display: none; 
                      &:before,
                      &:after {
                        width: 16px; 
                        right: 30px; 
                        background: #212121; 
                      }
                    }
                    &.act {
                      .arr-menu {
                        &:after {
                          width: 0 !important; 
                        }
                      }
                    }
                  }    
                  > ul {
                    li {
                      a {
                        font-size: 1.125rem !important; 
                      }
                    }
                  }
                  &:hover {
                    > ul {
                      display: none; 
                    }
                    > a {
                      .arr-menu {
                        &:after {
                          width: 17px; 
                        }
                      }
                    }
                  }                
                }
                &:hover{
                  .sub-nav{
                    position: relative;
                    display: none; 
                    ul{
                      li{
                        a{
                          &::after{
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
              &.product-menu{
                .sub-nav{
                  width: 100%; 
                  left: 0; 
                  transform: unset; 
                  &::before{
                    display: none;
                  }
                  &.sub-2{
                    position: relative;
                    width: 100%;
                  }
                  > ul{
                    width: 100%;
                    li{
                      a{
                        font-size: 1.5rem;
                        
                      }
                      ul{
                        width: 100%;
                        border-left: 0; 
                      }
                    }
                  }
                }
              }
            }
          }
          > ul {
            > li {
              &.product-menu {
                .main-sub {
                  > ul {
                    width: auto;
                  }
                  ul {
                    max-height: none;
                    min-height: 0;
                  }
                  .sub-child {
                    &:hover {
                      > .sub-menu {
                        display: none;
                        transition: none;
                      }
                    }
                  
                  }
                }
              }
            }
          }
        }
        .search-header{
          // display: none;
          margin-left: 0; 
          position: absolute;
          width: 100%; 
          top: 25px; 
          left: 0; 
          padding: 0 75px;  
					z-index: 4;          
          .ico-src {
            display: none; 
          }
          .block-search {
            width: 100%; 
            top: 0; 
            position: relative; 
            height: 50px; 
            form {
              input {
                border-radius: 8px; 
                height: 50px; 
                line-height: 50px; 
                padding-left: 24px; 
                border-color: #BDBDBD; 
              }
              button {
                top: 10px; 
              }
            }
          }
        }
        .lang{
          padding: 30px 75px 0;
          position: fixed;
          margin-left: 0; 
          width: 100%; 
          bottom: 36px; 
          left: 0; 
					z-index: 5;
					background: #fff; 
          .txt-title {
            position: absolute;
            left: 75px; 
            top: 0; 
            display: block; 
            color: #075289; 
            font-weight: 700; 
            opacity: 1; 
          }
          a, span{
            // font-size: 1.75rem;
            font-size: 18px; 
            font-weight: bold; 
            color: #4D5C6B; 
            &:hover, 
            &.active{
              color: #075289;
            }
          }
        }
    }
    &.float{
      .main-header{
        top: 75px;
				min-height: calc(100% - 75px);
    		height: calc(100% - 75px);
      }
      .burger-ico{
        span{
          background: #075289;
        }
      }
      .right-mob{
        .round{
          color: #075289;
          border-color: #075289;
          &::after{
            background: #075289;
          }
        }
      }
    }
    .wrapper{
      .logo{
        padding: 24px 0;
        width: 230px;
      }
    }
    .right-mob{
      display: block;
    }
    .burger-ico{
      display: block;
    }
    
  }

  .nobanner {
    header {
      .main-header{
        top: 81px;
      }
      .burger-ico{
        span{
          background: #075289;
        }
      }
      .right-mob{
        .round{
          color: #075289;
          border-color: #075289;
          &::after{
            background: #075289;
          }
        }
      }
    }
  }
  
  .chat {
    width: 60px;
    height: 60px;
    right: 20px;
  }
  footer {
    .main-footer {
      .wrapper {
        position: relative; 
        flex-wrap: wrap; 
        justify-content: flex-start;  
      }
      .col {
        &:nth-child(1) {
          flex:0 0 28%;
          max-width: 28%; 
          padding-right: 30px; 
        }
        &:nth-child(2) {
          flex:0 0 32%;
          max-width: 32%;
        }
        &:nth-child(3) {
          flex:0 0 20%;
          max-width: 20%;
        }
        &:nth-child(4) {
          flex:0 0 20%;
          max-width: 20%;
        }
        &:nth-child(5) {
          display: none; 
        }       
        .sosmed {
          margin-bottom: 10px; 
          li {
            padding-right: 10px; 
          }
        }
        .mobile-only{
          display: block;
        }
      }
    }
  }

}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  header{
    height: 110px;
    .main-header{      
      // padding-top: 20px; 
      padding-bottom: 130px; 
      nav{
        ul{
          li{
            a{
              font-size: 1.5rem;
              padding: 10px 0;
              margin-left: 45px; 
              margin-right: 45px;
              // &.round{
              //   display: inline-block;
              //   border: 2px solid #075289;
              // }
            }
            &.have-sub {
              .arrow {
                top: 10px; 
                right: 45px;
              }
              > a {
                margin-left: 45px; 
                margin-right: 45px; 
              }
              .sub-child {
                .arrow2 {
                  top: 2px; 
                }
                > ul {
                  li {
                    a {
                      font-size: 16px !important; 
                    }
                  }
                }
              }
            }
            &.product-menu {
              .sub-nav {
                > ul {
                  li {
                    a {
                      font-size: 16px; 
                    }
                  }
                }
              }
            }
            .sub-nav {
              padding-top: 15px;
              padding-bottom: 15px;
              padding-left: 45px; 
              padding-right: 45px; 
              li {
                a {
                  font-size: 16px; 
                }
              }
            }
          }
        }
      }
      .search-header {
        padding: 0 45px;
      }
      .lang{
        // padding: 10px 0;
        padding-left: 45px; 
        padding-right: 45px; 
        bottom: 45px; 
        a{
          // font-size: 1.5rem;
        }
       .txt-title {
        left: 45px;
       }
      }
			&::after {
				height: 130px; 
			}
    }
    .wrapper{
      .logo{
        width: 187px;
        padding: 30px 0;
      }
    }
    .burger-ico{
      span{
        background: #075289;
      }
    }
    .right-mob{
      .round{
        color: #075289;
        border-color: #075289;
        &::after{
          background: #075289;
        }
      }
    }
    &.float{
      height: 110px; 
      .wrapper{
        .logo{
          width: 187px;
          padding: 30px 0;
        }
      }
      .main-header {
        top: 110px; 
				min-height: calc(100% - 110px);
    		height: calc(100% - 110px);
      }
    }
  }
  
  footer{
    .logo-footer{
      margin-bottom: 48px;
      &.mobile-only{
        display: block;
      }
    }
    .wrapper{
      .col{
        &:nth-child(1), &:nth-child(5){
          display: none;
        }
        &:nth-child(2){
          padding: 0;
          // width: 230px;
          flex: 0 0 40%;
          max-width:40%;
        }
        &:nth-child(3){
          flex: 0 0 30%;
          max-width:30%;
        }
        &:nth-child(4){
          flex: 0 0 30%;
          max-width:30%;
          padding-right: 0;
        }
      }
    }
    .copyright{
      p{
        width: 324px;
      }
    }
  }


  .nobanner {
    header {
      .wrapper{
        .logo{
          width: 187px;
          padding: 30px 0;
        }
      }
    }
  }

}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  header{
    height: 72px;
    .main-header{
      // padding: 0 30px;
      min-height: calc(100% - 70px); 
      height: calc(100% - 70px); 
      top: 70px;
      padding-bottom: 105px; 
      nav{
        ul{
          li{
            a{
              font-size: 1rem;
              margin-left: 30px; 
              margin-right: 30px;
            }
            &.have-sub {
              .arrow {
                top: 5px;
                right: 30px; 
              }
              > {
                a {
                  margin-left: 30px; 
                  margin-right: 30px; 
                  padding-right: 40px; 
                }
              }
              .sub-child {
                .arrow2 {
                  top: 1px; 
                  right: 15px; 
                  &:before,&:after {
                    width: 12px;
                  }
                }
              }
            }
            .sub-nav{
              // padding: 10px;
              padding-left: 30px; 
              padding-right: 30px; 
              h6{
                font-size: 0.75rem;
                margin-bottom: 20px;
              }
              li {
                a {
                  font-size: 15px;                    
                }
              }
              ul {
                li {
                  a {
                    padding: 5px 0;
                    font-size: 15px;  
                  }
                  .sub-menu {
                    padding-left: 15px;
                  }
                }
              }
            }
            &.product-menu{
              .sub-nav{
                > ul{
                  li{
                    a{
                      font-size: 1rem;
                      padding-right: 30px; 
                    }
                    ul{
                      padding-left: 10px;
                    }
                    
                  }
                }
              }
            }
            &.contact {
              display: none; 
            }
          }
        }
      }
      .search-header {
        padding: 0 30px; 
      }
      .lang{
        bottom: 30px; 
        padding-left: 30px; 
        padding-right: 30px; 
        .txt-title {
          left: 30px; 
          font-size: 15px; 
        }
        a{
          font-size: 1rem;
        }
      }
			&::after {
				height: 105px; 
			}
    }
    .wrapper{
      .logo{
        width: 146px;
        padding: 16px 0;
      }
    }
    .burger-ico{
      width: 25px;
      height: 15px;
      span{
        height: 2px;
      }
    }
    .right-mob{
      display: flex; 
      .round{
        // display: none;
        color: #075289;
        border-color: #075289;
        font-size: 0;
        padding: 0; 
        border: 0;
        width: 34px;
        height: 34px; 
        background: url('/images/material/contact.png') no-repeat center center; 
        background-size: 24px 24px;  
        display: inline-block; 
        margin: -4px 35px 0 0;  
        &::after{
          background: #075289;
          display: none; 
        }
      }
    }
    &.float{
      height: 72px; 
      .main-header {
        top: 70px;
				min-height: calc(100% - 70px); 
				height: calc(100% - 70px);
      }
      .wrapper{
        .logo{
          width: 146px;
          padding: 16px 0;
        }
      }
    }
  }

  .chat {
    right: 15px; 
    bottom: 20px; 
  }
  footer{
    .logo-footer {
      margin-bottom: 30px; 
    }
    .wrapper{
      display: block;
      .col{
        width: 100%;
        padding: 0;
        margin-bottom: 30px;
        &:nth-child(2) {
          flex:0 0 100%;
          max-width: 100%; 
        }
        &:nth-child(3) {
          flex:0 0 100%;
          max-width: 100%; 
        }
        &:nth-child(4){
          flex:0 0 100%;
          max-width: 100%; 
          margin-bottom: 0;
          display: flex;
          flex-flow: wrap;
          justify-content: space-between;
          h6{
            width: 100%;
          }
        }
        .nav-footer {
           columns: 2;
          -webkit-columns: 2;
          -moz-columns: 2;
        }
        .sosmed {
          flex:0 0 50%;
          max-width: 50%;
        }
        .mobile-only {
         flex:0 0 50%;
        max-width: 50%;
        padding-left: 10px;  
        }
      }
    }
    .copyright{
      p{
        width: 100%;
        font-size: 0.813rem;
        line-height: 1.5;
        margin-bottom: 30px;
      }
    }
  }

  .nobanner {
    header {
      .wrapper{
        .logo{
          width: 146px;
          padding: 16px 0;
        }
      }
      .main-header {
        top: 72px; 
      }
    }
  }

}
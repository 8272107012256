/*=============================================================================================
Company    : PT Web Architect Technology - webarq.com
Document   : Stylesheet
Author     : Your Name
==============================================================================================*/

/* general
----------------------------------------------------------------------------------------------*/
@import "../component/layout";
@import "../pages/general";

.misc-error {
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  min-height: 600px;
  .middle-content {
    max-width: 860px;
    margin: 0 auto;
    padding: 50px 0;
    text-align: center;
    figcaption {
      span {
        font-size: 1.5rem;
        font-weight: 600;
        color: #5AB5E1;
      }
      h1{
        color: #075289;
        font-size: 3rem;
        line-height: 1;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 30px;
      }
      p {
        color: #999999;
        line-height: 1.5;
        margin: 0;
      }
      a {
        color: #FFF;
        display: inline-block;
        border-radius: 8px;
        min-width: 140px;
        background: #177EC3;
        font-size: 1rem;
        padding: 5px 51px 4px 32px;
        border-radius: 25px;
        line-height: 42px;
        transition: all ease-out 0.2s;
        position: relative;
        &:hover {
          background: #3396d8;
        }
        &:before {
          content: '';
          width: 10px;
          height: 15px;
          background: url('/images/material/arrow-misc.svg') no-repeat;
          position: absolute;
          right: 27px;
          top: 20px;
          bottom: 0;
        }
      }
    }
  }
}
.misc-container {
  margin-top: 140px;
  .title {
    h2 {
      color: #075289;
      font-weight: 600;
    }
    p {
      font-size: 0.75rem;
      color: #4D5C6B;
      font-style: italic;
    }
  }
  .wrap-search {
    margin-top: 40px;
    .box-white {
      background: #FFF;
      padding: 35px;
      border-radius: 10px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
      margin-bottom: 35px;
      transition: all ease-out 0.25s;
      h3 {
        color: #075289;
        font-size: 1.5rem;
        font-weight: 700;
      }
      p {
        font-size: 1.125rem;
        color: #212121;
        margin-bottom: 20px;
      }
      a {
        font-size: 0.875rem;
        font-weight: 700;
        color: #2fa6e0;
        opacity: 0.7;
        position: relative;
        transition: all ease-out 0.2s;
        &:hover {
          opacity: 1;
        }
        &:before {
          content: '';
          width: 9px;
          height: 13px;
          background: url('/images/material/arrow-search.svg') no-repeat;
          position: absolute;
          right: -25px;
          top: 3px;
          bottom: 0;
        }
      }
      &:hover{
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
        transition: all ease-out 0.25s;
      }
    }
  }
}
.wrap-tab{
  display: flex;
  justify-content: space-between;
  .nav-tab{
    width: calc( 100% - 654px );
    a{
      display: block;
      font-size: 13px;
      margin-bottom: 12px;
      color: #4D5C6B;
      font-weight: 400;
      transition: all ease-out 0.25s;
      &.active, &:hover{
        color: #075289;
        font-weight: 700;
        transition: all ease-out 0.25s;
      }
    }
  }
  .content-tab{
    display: none;
    width: 570px;
  }
}
.tab {
	overflow: hidden;
	border: 1px solid #ccc;
	background-color: #f0f0f0;
	transition: all 3s;
	button {
		background-color: inherit;
		float: left;
		border: none;
		outline: none;
		cursor: pointer;
		padding: 14px 16px;
		transition: 0.3s;
		font-size: 1.125rem;
		&:hover {
			background-color: #cccccc;
		}
		&.active {
			background-color: #333333;
			color: #ffffff;
		}
	}
}

.tabcontent {
	display: none;
	padding: 0.5rem 1.25rem;
	border: 1px solid #ccc;
	border-top: none;
}

// Helper Classes
.is-hidden {
	display: none !important;
}
.is-desktop {
	display: block;
	@media (max-width: 768px) {
		display: none !important;
	}
}
.is-mobile {
	display: none !important;
	@media (max-width: 768px) {
		display: block !important;
	}
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;       /* Remove default arrow */
	width: 100%;
	padding: 1rem;
	font-size: 1.125rem;
	background-color: #f0f0f0;
	border: none;
}

select::-ms-expand {
  display: none;
}

.select {
	position: relative;
	@media (max-width: 768px) {
		&:after {
			content: '';
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg stroke='none'%3E%3Cpath d='M21.293,12.293,16,17.586l-5.293-5.293a1,1,0,0,0-1.414,1.414l6,6a1,1,0,0,0,1.414,0l6-6a1,1,0,0,0-1.414-1.414Z' fill='%23000000'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
			background-position: center;
			background-size: contain;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 1rem;
			pointer-events:none;
			width: 32px;
			height: 32px;
		}
	}
}



/* responsive desktop
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 1600px) {}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
  .wrap-tab {
    flex-direction: column;
    .nav-tab {
      width: 100%;
      margin-bottom: 30px; 
      border:1px solid #E6EEED; 
      border-radius: 4px; 
      background: #fff; 
      position: relative;
      &:after {
        position: absolute;
        width: 12px; 
        height: 8px; 
        content: ""; 
        top: 21px;  
        right: 22px; 
        background: url('/images/material/arr-down-sm.svg') no-repeat 0 0;
        background-size: 12px 8px;   
      }
      a {
        display: none; 
        margin-bottom: 0; 
        min-height: 50px; 
        line-height: normal;
        padding: 16px 56px 16px 16px;  
        box-sizing: border-box; 
        &.active {
          display: block; 
        }
      }
      &.act {
        a {
          display: block; 
        }
      }
    }
    .content-tab {
      width: 100%; 
    }
    .tab-container {

    }
  }
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .misc-error{
    .middle-content{
      max-width: unset;
      margin: 0 45px;
      width: 100%;
      figcaption{
        h1{
          font-size: 1.75rem;
        }
        h5{
          font-size: 1rem;
          font-weight: 400;
        }
      }
    }
  }
  .misc-container{
    padding-top: 48px;
    margin-top: 0; 
  }
  .wrap-tab{
    display: block;
    .nav-tab{
      width: 100%;
      margin-bottom: 30px;
      a{
        font-size: 1rem;
      }
    }
    .content-tab{
      width: 100%;
    }
  }
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .misc-error{
    .middle-content{
      figcaption{
        img{
          height: 77px;
        }
        h1{
          font-size: 1.5rem;
          line-height: 1.16;
        }
      }
    }
  }
  .misc-container{
    padding-top: 0;
    margin-top: 40px;
    .title{
      h2{
        font-size: 1.5rem;
      }
    }
    .wrap-search{
      .box-white{
        padding: 24px;
        h3{
          font-size: 1.063rem;
        }
        p{
          font-size: 0.938rem;
        }
      }
    }
  }
}